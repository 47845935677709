
import HeroImg from "../../assets/mainItems/heroImg.png";
import ArticleStructure from "../../components/Articles/ArticleStructure";
import "./Articles.css";

import Navbar from "../../components/ui/navbar/Navbar";

const articles = [
    {
        "id": 1,
        "title": "Classically trained Chef, Ronicca Whaley decided to leap into the food truck world with her latest venture, Shiso Crispy.",
        "showImage": true,
        "imgFormat": "png"
    },
    {
        "id": 2,
        "placeTitle": "Omaha, NE. 1986",
        "title": "The Beginning",
        "text": ["Ronicca Whaley comes from a lineage of female chef entrepreneurs. Her grandmother launched a food service business, her aunt operated two restaurants, and her mother owned a catering company. And, funny enough, her grandfather had a food truck in Naples, Florida.", "From a young age, she was trained in the arts of American home cooking. Though, Ronicca came into her own while pursuing her three culinary degrees.", "Ronicca’s early career was spent like most chefs in training, sweating over hot stoves at several high-end restaurants in Omaha. She was the private chef for Dennis Paul Circo, Chief Executive Officer of Circo Enterprises for several years. After moving to St. Pete in 2016, she worked as the executive chef at The Oyster Bar."],
        "showImage": true,
        "imgFormat": "png"
    },
    {
        "id": 3,
        "placeTitle": "Kuala Lumpur, MY. 2016",
        "title": "The First Ventures",
        "text": ["Ronicca quickly became known as a food hustler, which led her to an introduction with a visiting restaurateur from Malaysia. He pitched her the opportunity to move to Kuala Lumpur and work with the Prime Minister of Malaysia, who was starting a 250-food truck franchise. A week later, she was on a plane to the Orient.", "After catching the entrepreneurial bug in Asia, she returned to Florida and started writing recipes with a national food supplier.", "Later, Ronicca got the wild idea to start whole selling gyoza (pan-fried dumplings). So on a whim, she bought a $20K gyoza machine from Japan. To this day, she sells up to 20,000 gyoza a month to restaurants across St. Petersburg."],
        "showImage": false,
        "imgFormat": "png"
    }, {
        "id": 4,
        "placeTitle": "St. Pete, FL. 2019",
        "title": "A New Challenge",
        "text": ["Whole selling gyoza wasn't enough for Ronicca. She wanted back in the customer-facing action. Taking what she learned in Malaysia, she decided to start a food truck business. It took her nearly a year to build out her first food truck from scratch. But her timing couldn't have been better. Two months after parking in her first lot, covid hit the States, shuttering restaurants across the city. Only food trucks were allowed to keep operating due to social distancing.", "She has been off to the races ever since. Ronicca has been featured in the New York Times, a two-time contestant on Food Network's 'Guy's Grocery Games,' and made more people drop their jaws in Dirty Rice extacy.", "And don’t worry, she has a lot more to come."],
        "showImage": true,
        "imgFormat": "png"
    }
]

const Articles = () => {
    return (
        <div>
            <a href="#articles" className="skip-to-main">Skip to main content</a>
            <Navbar />
            <div className="spacer"></div>
            <div className="articles-main-info-wrapper" id="articles">
                <img src={HeroImg} alt="main show food" className="image-style" />
                <div className="articles-wrapper" >
                    <div className="articles-title" role="heading" aria-level="1" >The Chef</div>
                    <section className="article-component-structures-wrapper">
                        {articles.map((data) => {
                            return <ArticleStructure articleInfo={data} key={`article#${data.id}`} />
                        })}
                    </section>
                </div>
            </div>

        </div>
    );
};

export default Articles;
