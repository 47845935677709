
import HeroImg from "../../assets/mainItems/heroImg.png";

import PressStructure from "../../components/Press/PressStructure";

import Navbar from "../../components/ui/navbar/Navbar";

import "./Press.css";

const press = [
    {
        "id": 1,
        "date": "6/22/22",
        "title": "Shiso Crispy on Guy’s Grocery Games Again",
        "text": "Beloved St. Pete food truck Shiso Crispy will be featured on Food Network’s ‘Guy’s Grocery Games’. This is the owner and operator Ronicca Whaley's second time competing on the popular cooking show.",
        "urlRedirection": "https://www.cltampa.com/food-drink/beloved-st-pete-food-truck-shiso-crispy-will-be-featured-on-food-networks-guys-grocery-games-13661689",
        "imgFormat": "png"
    },
    {
        "id": 2,
        "date": "5/11/22",
        "title": "WTSP: Great Day Dining",
        "text": "From dumplings to rangoons to bao buns, Shiso Crispy food truck serves up delicious Japanese cuisine.",
        "urlRedirection": "https://www.wtsp.com/article/features/great-day-live/great-day-dining/great-day-dining-shiso-crispy-greatdaylive-tampa-fun-food-trucks/67-8dbd3d35-9647-4851-a569-cb218218c9e1",
        "imgFormat": "png"
    },
    {
        "id": 3,
        "date": "12/1/21",
        "title": "The Legal Ledger",
        "text": "The Beginning of a Food Truck Empire.",
        "urlRedirection": "https://www.facebook.com/shisocrispy/photos/so-this-is-dope-just-got-it-in-the-mail-thanks-denmonpearlman-%EF%B8%8F-seriously-you-gu/780495593346830/",
        "imgFormat": "png"
    },
    {
        "id": 4,
        "date": "11/1/21",
        "title": "Guy's Grocery Games",
        "text": "Founder of Shiso Crispy, Ronnicca Whaley wins Guy's Grocery Games (TV Series) Extreme Frozen Games (2021)",
        "urlRedirection": "https://www.imdb.com/name/nm13111600/",
        "imgFormat": "png"
    },
    {
        "id": 5,
        "date": "9/30/21",
        "title": "The Highly Recommended",
        "text": "Are you looking for one of the best food trucks in St. Petersburg / Tampa, Florida?",
        "urlRedirection": "https://thehighlyrecommended.org/shiso-crispy-st-pete-fl-highlyrecommended/",
        "imgFormat": "jpg"
    },
    {
        "id": 6,
        "date": "8/1/21",
        "title": "Tampa Bay Times",
        "text": "Best of the Best 2021",
        "urlRedirection": "https://issuu.com/timescreative/docs/bestofthebest2021",
        "imgFormat": "jpeg"
    },
    {
        "id": 7,
        "date": "6/8/21",
        "title": "The New York Times",
        "text": "How Food Trucks Endured and Succeeded During the Pandemic.",
        "urlRedirection": "https://www.nytimes.com/2021/05/24/business/food-trucks-pandemic-covid.html",
        "imgFormat": "jpg"
    },
    {
        "id": 8,
        "date": "4/21/21",
        "title": "St. Petersburg Foodies",
        "text": "Ronicca Whaley – if you remember anything at all after reading this review, remember that name. ",
        "urlRedirection": "https://stpetersburgfoodies.com/restaurants/restaurant-reviews/shiso-crispy-is-making-big-waves-in-st-pete/",
        "imgFormat": "jpg"
    },
    {
        "id": 9,
        "date": "2/8/21",
        "title": "Sponsored by Verizon",
        "text": "We're outfitting local small businesses like Shiso Crispy, Mastry’s Brewing & Prymel Elements with 5G products & services to help keep 'em connected and growing. ",
        "urlRedirection": "https://twitter.com/verizon/status/1358566348339691521",
        "imgFormat": "jpg"
    },
    {
        "id": 10,
        "date": "8/21/20",
        "title": "Tampa Bay Times",
        "text": "Best of the Best 2020",
        "urlRedirection": "https://issuu.com/timescreative/docs/bestofthebest2020",
        "imgFormat": "jpg"
    },
    {
        "id": 11,
        "date": "6/18/20",
        "title": "ABC Action News",
        "text": "This Japanese fusion food truck takes it to a whole new level.",
        "urlRedirection": "https://www.youtube.com/watch?v=aOLB9wbgZx8&feature=youtu.be",
        "imgFormat": "jpg"
    }
]

const Press = () => {
    return (
        <div>
            <a href="#press-releases" className="skip-to-main">Skip to main content</a>
            <Navbar />
            <div className="spacer"></div>
            <div className="press-main-info-wrapper" id="press-releases">
                <img src={HeroImg} alt="main show food" className="image-style" />
                <div className="press-wrapper">
                    <div className="press-title" role="heading" aria-level="1">Press</div>
                    <section className="press-singular-structure-wrapper">
                        {press.map((data) => {
                            return <PressStructure articleInfo={data} key={`article#${data.id}`} />
                        })}
                    </section>
                </div>
            </div>

        </div>
    );
};

export default Press;
