import './ArticleStructure.css'

const ArticleStructure = ({ articleInfo }) => {

    return <div className='article-structure-wrapper'>
        {articleInfo.placeTitle && <div className='article-place-date' role="heading" aria-level="2" >{articleInfo.placeTitle}</div>}
        {articleInfo.title.length > 0 && <div className='article-title'>{articleInfo.title}</div>}
        {articleInfo.text && <div className='article-content-wrapper'>
            {articleInfo.text.map((info, index) => {
                return <div key={`Article ${articleInfo.id}.${index}`}>{info}</div>
            })}
        </div>}
        {articleInfo.showImage && <img src={require(`../../assets/articleImages/art${articleInfo.id}.${articleInfo.imgFormat}`)} alt={`${articleInfo.title}`} className='article-structure-img' />}
    </div>
}

export default ArticleStructure