import ButtonFormat from "../shared/ButtonFormat/ButtonFormat"

import './PressStructure.css'

const PressStructure = ({ articleInfo }) => {

    return <div className='press-structure-wrapper'>
        <div className="press-structure-img-wrapper">
            <img src={require(`../../assets/pressImages/press${articleInfo.id}.${articleInfo.imgFormat}`)} alt={`${articleInfo.title}`} className='press-structure-img' />
        </div>
        <div className="press-structure-information-wrapper">
            <div className='press-place-date'>{articleInfo.date}</div>
            <div className='press-title-structure' role="heading" aria-level="2">{articleInfo.title}</div>
            <div className='press-content-text'>{articleInfo.text}</div>
            <ButtonFormat
                btnSize={1.2}
                showArrow={true}
                arrowAtStart={false}
                arrowColor="#d4252b"
                showBorder={false}
                borderColor="transparent"
                backgroundColor="transparent"
                fontColor="#d4252b"
                capitalizeText={true}
                btnText="Read more"
                url={articleInfo.urlRedirection}
                removePadding={false}
            />

        </div>
    </div>
}

export default PressStructure